/*
 * project.less
 * -----------------------------------------------
*/
.project {
  .thumb {
    position: relative;
    overflow: hidden;
    &:after {
      background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
      content: "";
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition-duration: 0.3s;
      width: 100%;
    }
  }
  .hover-link {
    left: 44%;
    opacity: 0;
    position: absolute;
    top: 20%;
    transition-duration: 0.3s;
    z-index: 9;
    i {
      color: @white-base;
      display: block;
      font-size: 20px;
      padding: 10px;
    }
  }
  .project-details {
    background: @white-base none repeat scroll 0 0;
    transition-duration: 0.3s;
  }
  &:hover {
    .thumb::after {
      border: 10px solid rgba(255, 255, 255, 0.2);
      opacity: 1;
    }
    .hover-link {
      opacity: 1;
      top: 44%;
    }
    .project-details {
      border-bottom: 3px solid @white-base;
      .title, .sub-title {
        color: @white-base;
      }
    }
  }
}