@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        autocarepress
Version:        1.0
Last change:    02.20.2017
Primary use:    autocarepress - Factory & Industrial Business Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/

// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../fonts/icomoon/style.css";
@import "../css/flaticon-set-carfixing.css";
@import "../css/utility-classes.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800|Raleway:400,300,200,500,700,600,800|Trirong:100,200,300,400,500,600,700,800,900);

 
// Table of contents
@import "less-autocarepress/table-of-content.less";

 
// Initialize Variables
@import "less-autocarepress/variables.less";
@import "less-autocarepress/mixins.less";
// Typography
@import "less-autocarepress/typography.less";

// Common Styles
@import "less-autocarepress/common.less";
@import "less-autocarepress/extra.less";
@import "less-autocarepress/overlay.less";

// Header
@import "less-autocarepress/header.less";

// Nav
@import "less-autocarepress/nav.less";

// Content Blocks
@import "less-autocarepress/topbar.less";
@import "less-autocarepress/inner-header-title.less";
@import "less-autocarepress/vertical-nav.less";
@import "less-autocarepress/menu-full-page.less";
@import "less-autocarepress/boxed-layout.less";
@import "less-autocarepress/form.less";
@import "less-autocarepress/side-push-panel.less";
@import "less-autocarepress/box-hover-effect.less";
@import "less-autocarepress/gallery-isotope.less";
@import "less-autocarepress/sliders.less";
@import "less-autocarepress/home.less";
@import "less-autocarepress/about.less";
@import "less-autocarepress/project.less";
@import "less-autocarepress/volunteer.less";
@import "less-autocarepress/contact.less";
@import "less-autocarepress/event.less";
@import "less-autocarepress/practice-area.less";
@import "less-autocarepress/services.less";
@import "less-autocarepress/job.less";
@import "less-autocarepress/shop.less";
@import "less-autocarepress/blog.less";

// Shortcodes
@import "less-autocarepress/shortcodes.less";


// Widgets
@import "less-autocarepress/widgets.less";


// Footer
@import "less-autocarepress/footer.less";